<template>
  <TmPage data-title="About" hide-header>
    <div class="card">
      <h1>About</h1>
      <h2 class="c5">
        What is Posichain Staking?
      </h2>
      <p>
        Posichain Staking is a user interface for sending and receiving POSI tokens,
        viewing your transaction history, delegating your POSIs, and
        participating in governance on the Posichain.
      </p>
    </div>

    <div class="card">
      <h2 class="c5">
        Security
      </h2>
      <p>
        Posichain Staking is non-custodial. We believe that maintaining ownership over your
        keys is an important part of participating in these networks.
      </p>
      <p>
        <b>
          For your safety and security, Posichain Staking will not ask for or store your
          private keys or seed phrases.</b
        >
      </p>
      <p>
        To learn more about our security policies on Posichain Staking, please visit our
        <router-link to="security"> security page </router-link>.
      </p>
    </div>

<!--    <div class="card">-->
<!--      <h2 class="c5">-->
<!--        Team-->
<!--      </h2>-->
<!--      <div class="flex-row">-->
<!--        <div class="profile">-->
<!--          <img-->
<!--            src="~assets/images/team/fabo.jpg"-->
<!--            alt="Profile photo for Fabian Weber"-->
<!--          />-->
<!--          <div class="profile-info">-->
<!--            <span>Fabian Weber</span>-->
<!--            <span class="flag">🇩🇪</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="profile">-->
<!--          <img-->
<!--            src="~assets/images/team/jordan.jpg"-->
<!--            alt="Profile photo for Jordan Bibla"-->
<!--          />-->
<!--          <div class="profile-info">-->
<!--            <span>Jordan Bibla</span>-->
<!--            <span class="flag">🇨🇦</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="card">
      <h2 class="c5">
        Contact
      </h2>
      <p>
        Please let us know what you think! You can find us on
        <a
          href="https://github.com/PositionExchange/posichain"
          target="_blank"
          rel="noopener norefferer"
        >
          GitHub</a
        >
        and
        <a
          href="https://twitter.com/POSIChainOrg"
          target="_blank"
          rel="noopener norefferer"
          >Twitter</a
        >, email us at <a href="mailto:contact@posichain.org"></a>.
      </p>
    </div>
  </TmPage>
</template>

<script>
import TmPage from "common/TmPage"

export default {
  name: `page-about`,
  components: {
    TmPage
  }
}
</script>

<style scoped src="../../styles/content-page.css"></style>
